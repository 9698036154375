define('ember-cp-validations/validations/validator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (arg1, options) {
    var props = {
      options: Ember.isNone(options) ? {} : options
    };

    if (typeof arg1 === 'function') {
      Ember.deprecate('[ember-cp-validations] `validator` no longer directly accepts ' + 'a function. Please use the inline validator syntax:' + "\n\nvalidator('inline', { validate() {} )\n\n", false, { id: 'ember-cp-validations.inline-validator', until: '4.2.0' });
      props.options.validate = arg1;
      props._type = 'inline';
    } else if (typeof arg1 === 'string') {
      props._type = arg1;
    } else {
      throw new TypeError('[ember-cp-validations] Unexpected type for first validator argument — It must be a string.');
    }

    return props;
  };
});