define('ember-component-css/initializers/route-styles', ['exports', 'ember-component-css/pod-names'], function (exports, _podNames) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  Ember.Router.reopen({
    didTransition: function didTransition(routes) {
      this._super.apply(this, arguments);

      var classes = [];
      for (var i = 0; i < routes.length; i++) {
        var route = routes[i];
        var currentPath = route.name.replace(/\./g, '/');

        if (_podNames.default[currentPath]) {
          Ember.getOwner(this).lookup('controller:' + route.name).set('styleNamespace', _podNames.default[currentPath]);
          classes.push(_podNames.default[currentPath]);
        }
      }

      Ember.getOwner(this).lookup('controller:application').set('routeStyleNamespaceClassSet', classes.join(' '));
    }
  });

  function initialize() {}

  exports.default = {
    initialize: initialize
  };
});