define('ember-cli-flash/services/flash-messages', ['exports', 'ember-cli-flash/flash/object', 'ember-cli-flash/utils/object-without'], function (exports, _object, _objectWithout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isEmpty: Ember.computed.equal('queue.length', 0).readOnly(),
    _guids: Ember.computed.mapBy('queue', '_guid').readOnly(),

    arrangedQueue: Ember.computed.sort('queue', function (a, b) {
      if (a.priority < b.priority) {
        return 1;
      } else if (a.priority > b.priority) {
        return -1;
      }
      return 0;
    }).readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      this._setDefaults();
      this.queue = Ember.A();
    },
    add: function add() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this._enqueue(this._newFlashMessage(options));

      return this;
    },
    clearMessages: function clearMessages() {
      var flashes = Ember.get(this, 'queue');

      if (Ember.isNone(flashes)) {
        return;
      }

      flashes.clear();

      return this;
    },
    registerTypes: function registerTypes() {
      var _this = this;

      var types = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Ember.A();

      types.forEach(function (type) {
        return _this._registerType(type);
      });

      return this;
    },
    peekFirst: function peekFirst() {
      return Ember.get(this, 'queue.firstObject');
    },
    peekLast: function peekLast() {
      return Ember.get(this, 'queue.lastObject');
    },
    getFlashObject: function getFlashObject() {
      var errorText = 'A flash message must be added before it can be returned';
      (false && !(Ember.get(this, 'queue').length) && Ember.assert(errorText, Ember.get(this, 'queue').length));


      return this.peekLast();
    },
    _newFlashMessage: function _newFlashMessage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      (false && !(Ember.get(this, 'defaultPreventDuplicates') ? options.message : true) && Ember.assert('The flash message cannot be empty when preventDuplicates is enabled.', Ember.get(this, 'defaultPreventDuplicates') ? options.message : true));


      var flashService = this;
      var allDefaults = Ember.getWithDefault(this, 'flashMessageDefaults', {});
      var defaults = (0, _objectWithout.default)(allDefaults, ['types', 'injectionFactories', 'preventDuplicates']);

      var flashMessageOptions = Ember.merge(Ember.copy(defaults), { flashService: flashService });

      for (var key in options) {
        var value = Ember.get(options, key);
        var option = this._getOptionOrDefault(key, value);

        Ember.set(flashMessageOptions, key, option);
      }

      return _object.default.create(flashMessageOptions);
    },
    _getOptionOrDefault: function _getOptionOrDefault(key, value) {
      var defaults = Ember.getWithDefault(this, 'flashMessageDefaults', {});
      var defaultOption = Ember.get(defaults, key);

      if (Ember.typeOf(value) === 'undefined') {
        return defaultOption;
      }

      return value;
    },
    _setDefaults: function _setDefaults() {
      var defaults = Ember.getWithDefault(this, 'flashMessageDefaults', {});

      for (var key in defaults) {
        var classifiedKey = Ember.String.classify(key);
        var defaultKey = 'default' + classifiedKey;

        Ember.set(this, defaultKey, defaults[key]);
      }

      this.registerTypes(Ember.getWithDefault(this, 'defaultTypes', Ember.A()));
    },
    _registerType: function _registerType(type) {
      var _this2 = this;

      (false && !(type) && Ember.assert('The flash type cannot be undefined', type));


      this[type] = function (message) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var flashMessageOptions = Ember.copy(options);
        Ember.setProperties(flashMessageOptions, { message: message, type: type });

        return _this2.add(flashMessageOptions);
      };
    },
    _hasDuplicate: function _hasDuplicate(guid) {
      return Ember.get(this, '_guids').includes(guid);
    },
    _enqueue: function _enqueue(flashInstance) {
      var preventDuplicates = Ember.get(this, 'defaultPreventDuplicates');

      if (preventDuplicates) {
        var guid = Ember.get(flashInstance, '_guid');

        if (this._hasDuplicate(guid)) {
          (false && Ember.warn('Attempting to add a duplicate message to the Flash Messages Service', false, {
            id: 'ember-cli-flash.duplicate-message'
          }));

          return;
        }
      }

      return Ember.get(this, 'queue').pushObject(flashInstance);
    }
  });
});